/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import { DismissButton, useModal, useOverlay, usePreventScroll } from "@react-aria/overlays";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useWindowSize } from "react-use";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { t } from "~src/designSystem/theme";

import { ChevronRight } from "./ChevronRight";

/* Dialog Header height */
const HEADER_HEIGHT = 57;
/* Breakpoint for turning the dialog into the fullpage vs halfpage variants */
export const DIALOG_FULLPAGE_BREAKPOINT = 800; // px

export type IDialogVariation = "fullPage" | "halfPage";

export type IDialogProps = {
  /* Overlay props */

  /** Whether the overlay is currently open. */
  isOpen?: boolean;
  /** Handler that is called when the overlay should close. */
  onClose?: () => void;
  /**
   * Whether to close the overlay when the user interacts outside it.
   * @default false
   */
  isDismissable?: boolean;
  /** Whether the overlay should close when focus is lost or moves outside it. */
  shouldCloseOnBlur?: boolean;
  /**
   * Whether pressing the escape key to close the overlay should be disabled.
   * @default false
   */
  isKeyboardDismissDisabled?: boolean;
  /**
   * When user interacts with the argument element outside of the overlay ref,
   * return true if onClose should be called.  This gives you a chance to filter
   * out interaction with elements that should not dismiss the overlay.
   * By default, onClose will always be called on interaction outside the overlay ref.
   */
  shouldCloseOnInteractOutside?: (element: HTMLElement) => boolean;

  /* Dialog props */

  /**
   * Title of the Dialog
   */
  title: string;
  /**
   * Body of the Dialog
   */
  children: React.ReactNode;
  /**
   * Dialog variant
   * @default "fullPage"
   */
  variant: IDialogVariation;
  /**
   * Body of the expandable side column for "fullPage" variation.
   */
  sideColumn?: React.ReactChild;
};

export const Dialog: React.FC<IDialogProps> = (props: IDialogProps) => {
  const {
    title,
    children,
    onClose,
    isDismissable,
    isOpen,
    shouldCloseOnBlur,
    isKeyboardDismissDisabled,
    variant = "fullPage",
    sideColumn,
  } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = React.useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    {
      onClose,
      isOpen,
      isDismissable,
      shouldCloseOnBlur,
      isKeyboardDismissDisabled,
    },
    ref,
  );

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog({ role: "dialog" }, ref);

  // Animations for fullPage vs halfPage variants.
  const animationVariants = React.useMemo(() => {
    return {
      fadeInOut: {
        initial: "hidden",
        animate: "visible",
        exit: "hidden",
        transition: { easing: "ease" },
        variants: {
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        },
      },
      fullPageSlideUp: {
        hidden: { y: "100vh" },
        visible: {
          y: 0,
          transition: {
            duration: 0.2,
            transform: "cubic-bezier(0.18, 0.96, 0.1, 0.99)",
          },
        },
      },
      halfPageSlideIn: {
        hidden: { x: "100vw" },
        visible: {
          x: 0,
          transition: {
            duration: 0.2,
            transform: "cubic-bezier(0.18, 0.96, 0.1, 0.99)",
          },
        },
      },
    };
  }, []);

  // Track sideColumn expand/hide state.
  const [modalVariant, setModalVariant] = React.useState<IDialogVariation>(variant);
  const [isSideColumnExpanded, setIsSideColumnExpanded] = React.useState<boolean>(false);

  const onClickExpand = React.useCallback(() => {
    setIsSideColumnExpanded(true);
    // If we're on halfPage modal variant and the sideColumn is expanded,
    // transition to the fullPage variant.
    if (modalVariant === "halfPage") {
      setModalVariant("fullPage");
    }
  }, [modalVariant]);

  const onClickHide = React.useCallback(() => {
    setIsSideColumnExpanded(false);
    // Note: If the user was initially on the halfPage dialog variant and as a
    // result of expanding the side column they transitioned to fullPage, then on closing
    // the side column, we transition them back to the halfPage variant.
    if (variant === "halfPage") {
      setModalVariant("halfPage");
    }
  }, [variant]);

  // Handles Responsiveness. Changes the Dialog variant to fullPage or halfPage depending
  // on the current screen size.
  const windowSize = useWindowSize();
  useEffect(() => {
    // Transition to Full page variant if we're below our breakpoint.
    if (windowSize.width < DIALOG_FULLPAGE_BREAKPOINT) {
      setModalVariant("fullPage");
    } else if (!isSideColumnExpanded) {
      // Otherwise, if the side column wasn't already expanded, turn the dialog to Half page variant.
      setModalVariant("halfPage");
    }
  }, [windowSize, isSideColumnExpanded]);

  return (
    <Overlay {...underlayProps}>
      <OverlayInner {...animationVariants.fadeInOut}>
        <FocusScope contain restoreFocus autoFocus>
          <motion.div
            variants={
              variant === "fullPage"
                ? animationVariants.fullPageSlideUp
                : animationVariants.halfPageSlideIn
            }
            initial="hidden"
            exit="hidden"
            animate="visible"
          >
            <Modal
              variant={modalVariant}
              {...overlayProps}
              {...dialogProps}
              {...modalProps}
              ref={ref}
            >
              <Flexbox row justifyContent="flex-start">
                <MainColumn>
                  <Header>
                    <FaTimes className="close-stepper" onClick={onClose} />
                    <Spacer x="5" />
                    <Divider />
                    <Title {...titleProps}>{title}</Title>
                    {!isSideColumnExpanded &&
                      sideColumn !== undefined &&
                      windowSize.width > DIALOG_FULLPAGE_BREAKPOINT && (
                        <ActionWrapper
                          onClick={onClickExpand}
                          css={css`
                            margin-left: auto;
                          `}
                        >
                          <ChevronRight />
                          <ActionLabel>More info</ActionLabel>
                        </ActionWrapper>
                      )}
                  </Header>
                  <BodyWrapper>{children}</BodyWrapper>
                  <DismissButton onDismiss={onClose} />
                </MainColumn>
                {isSideColumnExpanded && windowSize.width > DIALOG_FULLPAGE_BREAKPOINT && (
                  <SideColumn>
                    <SideColumnHeader>
                      <ActionWrapper onClick={onClickHide}>
                        <ChevronRight
                          css={css`
                            transform: rotate(180deg);
                          `}
                        />
                        <ActionLabel>Hide</ActionLabel>
                      </ActionWrapper>
                    </SideColumnHeader>
                    <BodyWrapper>{sideColumn}</BodyWrapper>
                  </SideColumn>
                )}
              </Flexbox>
            </Modal>
          </motion.div>
          )
        </FocusScope>
      </OverlayInner>
    </Overlay>
  );
};

const MainColumn = styled.div`
  height: 100vh;
  flex-grow: 1;
`;

const SideColumn = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.components.Dialog.SideColumn.background};
  min-width: 375px;
  max-width: 375px;
`;

const SideColumnHeader = styled.div`
  background: ${({ theme }) => theme.components.Dialog.SideColumn.Header.background};
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  padding: ${t.c.spacing("0", "4", "0", "5")};
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

const ActionLabel = styled.div`
  color: ${({ theme }) => theme.components.Dialog.SideColumn.ActionLabel.color};
  font-size: ${({ theme }) => theme.components.Dialog.SideColumn.ActionLabel.font.size};
  font-weight: ${({ theme }) => theme.components.Dialog.SideColumn.ActionLabel.font.weight};
  line-height: ${({ theme }) => theme.components.Dialog.SideColumn.ActionLabel.font.lineHeight};
  text-transform: capitalize;
`;

const ActionWrapper = styled(Flexbox)`
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  gap: ${t.space[2].toString()};

  padding: ${t.c.spacing("0", "1", "0", "2")};
  height: 28px;
  width: fit-content;
  min-width: 164px;

  cursor: pointer;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

/** this exists because motion props dont play well with react.aria props */
const OverlayInner = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div<{ variant: IDialogVariation }>`
  background: ${({ theme }) => theme.components.Dialog.Overlay.background};
  height: 100vh;
  margin-left: auto;
  width: ${(props) => (props.variant === "halfPage" ? "50vw" : "100vw")};
`;

const Header = styled.div`
  background: ${({ theme }) => theme.components.Dialog.Header.background};
  border-bottom: 1px solid ${({ theme }) => theme.components.Dialog.Header.border};
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  padding: ${t.c.spacing("0", "4", "0", "8")};
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

const Title = styled.h1`
  margin-left: 20px;
  line-height: 1.8;
  text-transform: capitalize;
  font-size: ${({ theme }) => `${theme.components.Dialog.Header.Title.font.size}`};
  font-weight: ${({ theme }) => `${theme.components.Dialog.Header.Title.font.weight}`};
`;

const Divider = styled.div`
  height: 32px;
  width: 0;
  margin: 0 ${t.space[1].toString()};
  border-left: 1px solid ${({ theme }) => theme.components.Divider.default};
`;

const BodyWrapper = styled.div`
  padding: ${t.c.spacing("16", "16", "8", "16")};
  overflow: auto;
  height: calc(100% - ${HEADER_HEIGHT}px);
`;
