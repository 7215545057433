// Code generated by go run pkg/lists/generate; DO NOT EDIT.
import models from "~/src/shared/lists/types/models/schema.json";
import { Maybe, Scalars } from "~src/__generated__/graphql/types";
import { IDataType, IRenderType } from "~src/shared/lists/types/types";

export enum IListModel {
  customers = "customers",
  lists = "lists",
  lists_investor_asset_receipts = "lists_investor_asset_receipts",
  lists_investor_payments = "lists_investor_payments",
  lists_investor_transfers = "lists_investor_transfers",
  lists_investor_users = "lists_investor_users",
  lists_limit_buy_orders = "lists_limit_buy_orders",
  lists_payment_tasks = "lists_payment_tasks",
  lists_payments = "lists_payments",
  lists_sub_payments = "lists_sub_payments",
  lists_tradeable_contracts = "lists_tradeable_contracts",
  lists_trades = "lists_trades",
  lists_users = "lists_users",
  lists_vendor_bank_transactions = "lists_vendor_bank_transactions",
}

export type IListColumn<M extends IListModel> = {
  name: IListModelColumnName<M>;
  data_type: IDataType;
  render_type: IRenderType;
  dependent_columns: IListModelColumnName<M>[] | null;
  enriched_via?: string;
};

export type IListModelConfig<M extends IListModel> = {
  name: M;
  columns: { [K in IListModelColumnName<M>]: IListColumn<M> };
};

export type CustomersListModel = {
  email: Scalars["String"];
  full_name: Scalars["String"];
  domain: Scalars["String"];
  created_at: Scalars["timestamptz"];
  public_id: Scalars["String"];
  industry: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
  state: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  facebook_likes: Maybe<Scalars["numeric"]>;
  twitter_followers: Maybe<Scalars["numeric"]>;
  alexa_us_rank: Maybe<Scalars["numeric"]>;
  alexa_global_rank: Maybe<Scalars["numeric"]>;
  estimated_employees: Maybe<Scalars["numeric"]>;
  estimated_annual_revenue: Maybe<Scalars["String"]>;
  amount_raised: Maybe<Scalars["numeric"]>;
  type: Maybe<Scalars["String"]>;
  logo: Maybe<Scalars["String"]>;
};
export type ListsListModel = {
  title: Scalars["String"];
  model: Scalars["String"];
  created_at: Scalars["timestamptz"];
  slug: Scalars["String"];
  public_id: Scalars["String"];
};
export type ListsInvestorAssetReceiptsListModel = {
  public_id: Scalars["String"];
  pseudonym: Scalars["String"];
  created_at: Scalars["timestamptz"];
  currency: Scalars["currency"];
  purchase_amount: Scalars["numeric"];
  receivable_amount: Scalars["numeric"];
  asset_status: Scalars["String"];
  asset_rating: Scalars["String"];
  matched_at: Scalars["timestamptz"];
  ends_at: Scalars["timestamptz"];
  irr_bps: Scalars["numeric"];
  outstanding_balance: Scalars["numeric"];
  percentage_paid: Scalars["numeric"];
  lbo_public_id: Scalars["String"];
  investor_public_id: Scalars["String"];
  swapped_with: Scalars["String"];
  swapped_amount: Scalars["numeric"];
};
export type ListsInvestorPaymentsListModel = {
  public_id: Scalars["String"];
  ar_public_id: Scalars["String"];
  invd_public_id: Scalars["String"];
  created_at: Scalars["timestamptz"];
  payment_type: Scalars["String"];
  due_at: Scalars["timestamptz"];
  currency: Scalars["currency"];
  months_diff: Scalars["numeric"];
  amount: Scalars["numeric"];
};
export type ListsInvestorTransfersListModel = {
  public_id: Scalars["String"];
  status: Scalars["String"];
  due_at: Scalars["timestamptz"];
  currency: Scalars["currency"];
  initiated_at: Scalars["timestamptz"];
  cleared_at: Scalars["timestamptz"];
  payment_type: Scalars["String"];
  bank_name: Scalars["String"];
  amount: Scalars["numeric"];
  num_payments: Scalars["numeric"];
};
export type ListsInvestorUsersListModel = {
  name: Scalars["String"];
  email: Scalars["String"];
  status: Scalars["String"];
  public_id: Scalars["String"];
  investor_public_id: Scalars["String"];
};
export type ListsLimitBuyOrdersListModel = {
  status: Scalars["String"];
  public_id: Scalars["String"];
  created_at: Scalars["timestamptz"];
  asset_rating: Scalars["String"];
  is_active: Scalars["Boolean"];
  currency: Scalars["currency"];
  amount_limit: Scalars["numeric"];
  amount_unfilled: Scalars["numeric"];
  amount_filled_percentage: Scalars["numeric"];
  irr_bps: Scalars["numeric"];
  created_by_user_name: Scalars["String"];
  expires_at: Scalars["timestamptz"];
};
export type ListsPaymentTasksListModel = {
  vendor_name: Scalars["String"];
  vendor_client_id: Scalars["String"];
  public_id: Scalars["String"];
  due_at: Scalars["timestamptz"];
  num_sub_payments: Scalars["numeric"];
  payment_amount: Scalars["numeric"];
  payout_amount: Scalars["numeric"];
  vendor_repayment_status: Scalars["String"];
  is_pipe_entertainment: Scalars["Boolean"];
  currency: Scalars["currency"];
};
export type ListsPaymentsListModel = {
  amount: Scalars["numeric"];
  payment_status: Scalars["String"];
  num_contracts: Scalars["numeric"];
  due_date: Scalars["timestamptz"];
  currency: Scalars["currency"];
  payment_type: Scalars["String"];
  public_id: Scalars["String"];
};
export type ListsSubPaymentsListModel = {
  customer_display: Scalars["String"];
  amount: Scalars["numeric"];
  trade_date: Scalars["timestamptz"];
  due_at: Scalars["timestamptz"];
  initiated_at: Maybe<Scalars["timestamptz"]>;
  payment_status: Scalars["String"];
  currency: Scalars["currency"];
  customer_email: Scalars["String"];
  vendor_payment_id: Scalars["String"];
  pipe_agreement_id: Scalars["String"];
  public_id: Scalars["String"];
};
export type ListsTradeableContractsListModel = {
  customer_display: Scalars["String"];
  max_tradeable_term_length: Scalars["numeric"];
  mrr: Scalars["numeric"];
  base_payout: Scalars["numeric"];
  base_balance: Scalars["numeric"];
  interval_count: Scalars["numeric"];
  base_rate_bps: Scalars["numeric"];
  currency: Scalars["currency"];
  customer_email: Scalars["String"];
  public_id: Scalars["String"];
  vendor_public_id: Scalars["String"];
};
export type ListsTradesListModel = {
  customer_display: Scalars["String"];
  mrr: Scalars["numeric"];
  starts_date: Scalars["timestamptz"];
  term_length: Scalars["numeric"];
  payout: Scalars["numeric"];
  currency: Scalars["currency"];
  customer_email: Scalars["String"];
  interval_count: Scalars["numeric"];
  is_active: Scalars["Boolean"];
  public_id: Scalars["String"];
};
export type ListsUsersListModel = {
  name: Scalars["String"];
  email: Scalars["String"];
  status: Scalars["String"];
  role: Scalars["String"];
  public_id: Scalars["String"];
  status_if_enabled: Scalars["String"];
  vendor_public_id: Scalars["String"];
};
export type ListsVendorBankTransactionsListModel = {
  vendor_public_id: Scalars["String"];
  date: Scalars["timestamptz"];
  amount: Scalars["numeric"];
  description: Scalars["String"];
  is_pending: Scalars["Boolean"];
  label: Scalars["String"];
  merchant_name: Scalars["String"];
  payment_processor_name: Scalars["String"];
  account_public_id: Scalars["String"];
  account_display_name: Scalars["String"];
  public_id: Scalars["String"];
  currency: Scalars["currency"];
};

export type IListModelType<M extends IListModel> = {
  [IListModel.customers]: CustomersListModel;
  [IListModel.lists]: ListsListModel;
  [IListModel.lists_investor_asset_receipts]: ListsInvestorAssetReceiptsListModel;
  [IListModel.lists_investor_payments]: ListsInvestorPaymentsListModel;
  [IListModel.lists_investor_transfers]: ListsInvestorTransfersListModel;
  [IListModel.lists_investor_users]: ListsInvestorUsersListModel;
  [IListModel.lists_limit_buy_orders]: ListsLimitBuyOrdersListModel;
  [IListModel.lists_payment_tasks]: ListsPaymentTasksListModel;
  [IListModel.lists_payments]: ListsPaymentsListModel;
  [IListModel.lists_sub_payments]: ListsSubPaymentsListModel;
  [IListModel.lists_tradeable_contracts]: ListsTradeableContractsListModel;
  [IListModel.lists_trades]: ListsTradesListModel;
  [IListModel.lists_users]: ListsUsersListModel;
  [IListModel.lists_vendor_bank_transactions]: ListsVendorBankTransactionsListModel;
}[M];

export type IListModelColumnName<M extends IListModel> = keyof IListModelType<M>;

export const getListModelConfig = <M extends IListModel>(m: M): IListModelConfig<M> => {
  return models[m] as unknown as IListModelConfig<M>;
};
